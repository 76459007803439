import React from "react"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

function formatDate(date) {
  if (date) {
    return date.toLocaleDateString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    })
  }
  return ""
}

function PercentageSpray({ percent }) {
  return (
    <CircularProgressbarWithChildren value={percent}>
      {percent === null ? null : (
        <span className="text-sm font-bold text-secondary-900">{`${
          percent > 250 ? `250+` : Math.round(percent)
        }%`}</span>
      )}
    </CircularProgressbarWithChildren>
  )
}

export default function SpraySteps({
  percentageOfTarget,
  startDate,
  firstSpray,
  secondSpray,
  thirdSpray,
}) {
  let percent = 0
  if (startDate) {
    percent = percentageOfTarget
  }

  return (
    <div className="flex items-center justify-center py-8">
      <nav aria-label="Progress">
        <ol className="overflow-hidden">
          {thirdSpray && (
            <li className="relative pb-10">
              <div
                className="-ml-px absolute top-16 left-8 w-0.5 h-10 bg-gray-300"
                aria-hidden="true"
              ></div>
              <span className="relative flex items-start group">
                <span
                  className="z-10 flex items-center w-16"
                  aria-hidden="true"
                >
                  <PercentageSpray percent={percent}></PercentageSpray>
                </span>
                <span className="flex flex-col min-w-0 ml-4">
                  <span
                    className={`text-xs font-semibold tracking-wide text-secondary-900 uppercase`}
                  >
                    Third Spray
                  </span>
                  <span className="text-sm text-secondary-900">
                    {formatDate(thirdSpray)}
                  </span>
                </span>
              </span>
            </li>
          )}

          {secondSpray && (
            <li className={`relative pb-10 ${thirdSpray ? `opacity-50` : ``}`}>
              <div
                className="-ml-px absolute top-16 left-8 w-0.5 h-10 bg-gray-300"
                aria-hidden="true"
              ></div>
              <span className="relative flex items-start group">
                <span
                  className="z-10 flex items-center w-16"
                  aria-hidden="true"
                >
                  <PercentageSpray
                    percent={thirdSpray ? null : percent}
                  ></PercentageSpray>
                </span>
                <span className="flex flex-col min-w-0 ml-4">
                  <span
                    className={`text-xs font-semibold tracking-wide ${
                      thirdSpray ? `text-gray-500` : `text-secondary-900`
                    } uppercase`}
                  >
                    Second Spray
                  </span>
                  <span
                    className={`text-sm ${
                      thirdSpray ? `text-gray-500` : `text-secondary-900`
                    }`}
                  >
                    {formatDate(secondSpray)}
                  </span>
                </span>
              </span>
            </li>
          )}

          {firstSpray && (
            <li className={`relative pb-10 ${secondSpray ? `opacity-50` : ``}`}>
              <div
                className="-ml-px absolute top-16 left-8 w-0.5 h-10 bg-gray-300"
                aria-hidden="true"
              ></div>
              <span className="relative flex items-start group">
                <span
                  className="z-10 flex items-center w-16"
                  aria-hidden="true"
                >
                  <PercentageSpray
                    percent={secondSpray ? null : percent}
                  ></PercentageSpray>
                </span>
                <span className="flex flex-col min-w-0 ml-4">
                  <span
                    className={`text-xs font-semibold tracking-wide ${
                      secondSpray ? `text-gray-500` : `text-secondary-900`
                    } uppercase`}
                  >
                    First Spray
                  </span>
                  <span
                    className={`text-sm ${
                      secondSpray ? `text-gray-500` : `text-secondary-900`
                    }`}
                  >
                    {formatDate(firstSpray)}
                  </span>
                </span>
              </span>
            </li>
          )}

          {startDate && (
            <li className={`relative ${firstSpray ? `opacity-50` : ``}`}>
              <span className="relative flex items-start group">
                <span
                  className="z-10 flex items-center w-16"
                  aria-hidden="true"
                >
                  <PercentageSpray
                    percent={firstSpray ? null : percent}
                  ></PercentageSpray>
                </span>

                <span className="flex flex-col min-w-0 ml-4">
                  <span
                    className={`text-xs font-semibold tracking-wide ${
                      firstSpray ? `text-gray-500` : `text-secondary-900`
                    } uppercase`}
                  >
                    Start Date
                  </span>
                  <span
                    className={`text-sm ${
                      firstSpray ? `text-gray-500` : `text-secondary-900`
                    }`}
                  >
                    {formatDate(startDate)}
                  </span>
                </span>
              </span>
            </li>
          )}
        </ol>
      </nav>
    </div>
  )
}
