import GlobalStateContext from '../../context/globalStateContext'
import { setDate } from '../../utils/helpers'
import CustomInputEndDate from './customInputEndDate'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function EndDate({
  setSidebarOpen,
  isMobile = false,
  indexPageDispatch,
  lastHourWithData,
}) {
  const { dispatch, dateOfInterest } = React.useContext(GlobalStateContext)
  const [dd, setDD] = React.useState(dateOfInterest.date)

  function handleOnChange(d) {
    let now = new Date()
    now.setHours(lastHourWithData)
    now.setMinutes(0)
    now.setSeconds(0)
    const selectedYear = d.getFullYear()
    if (selectedYear === now.getFullYear()) {
      const maxDate = new Date(selectedYear, 5, 30, 23, 0)
      if (now > maxDate.getTime()) {
        setDD(maxDate)
        dispatch({
          type: 'setDateOfInterest',
          dateOfInterest: setDate(maxDate),
        })
      } else {
        setDD(now)
        dispatch({ type: 'setDateOfInterest', dateOfInterest: setDate(now) })
      }
    } else {
      const june30 = new Date(selectedYear, 5, 30, 23, 0)
      setDD(june30)
      dispatch({
        type: 'setDateOfInterest',
        dateOfInterest: setDate(june30),
      })

      indexPageDispatch({ type: 'initialState' })
    }
    if (isMobile) setSidebarOpen(false)
  }

  const june30Ms = new Date(new Date().getFullYear(), 5, 30, 23, 0).getTime()
  let maxDate = new Date()
  if (maxDate.getTime() > june30Ms) {
    maxDate = new Date(maxDate.getFullYear(), 5, 30, 23, 0)
  }
  // console.log(maxDate)
  // console.log(dd.getTime(), june30Ms, dd.getTime() > june30Ms)
  return (
    <DatePicker
      selected={
        dd.getTime() > new Date(dateOfInterest.year, 5, 30, 23, 0).getTime()
          ? new Date(dateOfInterest.year, 5, 30, 23, 0).getTime()
          : dd
      }
      onChange={handleOnChange}
      minDate={new Date(2018, 1, 0, 0)}
      maxDate={maxDate}
      showYearPicker
      dateFormat="M/d/yyyy"
      customInput={<CustomInputEndDate placeholderText="Select End Date" />}
    />
  )
}
