import SpraySteps from "./_spraySteps"
import React from "react"
import ReactTooltip from "react-tooltip"

export default function Block({
  block,
  editBlock,
  deleteBlock,
  percentageOfTarget,
}) {
  const {
    blockName,
    appleVariety,
    avgStyleLength,
    startDate,
    firstSpray,
    secondSpray,
    thirdSpray,
  } = block

  if (!block) return null
  return (
    <>
      <h2 className="mb-4 text-2xl font-semibold text-gray-600">{blockName}</h2>
      <div className="border border-gray-100 rounded-lg shadow-lg">
        <div className="flex items-center justify-between px-3 py-3 pb-2 font-semibold text-white border-b rounded-t-lg bg-secondary-800 sm:px-6 ">
          <div className="flex items-center justify-between flex-1">
            <div className="text-sm sm:text-base">
              <span className="hidden mr-1 font-light lg:inline-block">
                Apple Variety:
              </span>
              <span className="font-extrabold">{appleVariety.label}</span>
            </div>

            <div className="text-sm sm:text-base">
              <span className="hidden mr-1 font-light lg:inline-block">
                Avg Style Length:
              </span>
              <span className="font-extrabold">
                {avgStyleLength.length !== 0
                  ? `${Number(avgStyleLength).toFixed(3)}`
                  : `(__.___)`}{" "}
                <span className="hidden lg:inline-block">mm</span>
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between ml-6 md:ml-8">
            <button
              type="button"
              onClick={() => editBlock(block.id)}
              onKeyDown={() => editBlock(block.id)}
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-secondary-500 hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-600"
            >
              Edit Block
            </button>

            <span
              role="button"
              tabIndex="0"
              className="pl-1 sm:pl-2"
              onClick={() => deleteBlock(block.id)}
              onKeyDown={() => editBlock(block.id)}
              data-tip
              data-for="deleteBlock"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="w-5 h-5 sm:w-6 sm:h-6 hover:opacity-70"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <ReactTooltip id="deleteBlock" effect="solid" aria-haspopup="true">
              <span>Delete Block</span>
            </ReactTooltip>
          </div>
        </div>

        {startDate ? (
          <SpraySteps
            percentageOfTarget={percentageOfTarget}
            startDate={startDate}
            firstSpray={firstSpray}
            secondSpray={secondSpray}
            thirdSpray={thirdSpray}
          ></SpraySteps>
        ) : (
          <div className="flex items-center justify-center py-8">
            <h2 className="text-lg font-semibold tracking-wide text-gray-600 md:text-xl">
              Inactive - Select start date to activate block
            </h2>
          </div>
        )}
      </div>
    </>
  )
}
