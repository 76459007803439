import React from "react"

export default function References() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        Yoder, K.S., G.M. Peck, L.D. Combs, and R.E. Byers. 2013. Using a pollen
        tube growth model to improve bloom thinning for organic production. Acta
        Horticulturae 1001:207-214.{" "}
        <a
          href="https://doi.org/10.17660/ActaHortic.2013.1001.23"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://doi.org/10.17660/ActaHortic.2013.1001.23
        </a>
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Peck, G.M., L.D. Combs, C. DeLong, and K.S. Yoder. 2016. Precision Apple
        Flower Thinning using Organically Approved Chemicals. Acta Horticulturae
        1137:47-52.{" "}
        <a
          href="https://doi.org/10.17660/ActaHortic.2016.1137.7"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://doi.org/10.17660/ActaHortic.2016.1137.7
        </a>
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Peck,G.M., C.N. DeLong, L. Combs, and K.S. Yoder. 2017. Managing Apple
        Crop Load and Diseases with Bloom Thinning Applications in an
        Organically Managed ‘Honeycrisp’/‘MM.111’ Orchard. HortScience
        (523)-377–381.
        <a
          href="https://doi.org/10.21273/HORTSCI11412-16"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://doi.org/10.21273/HORTSCI11412-16
        </a>
      </p>
    </>
  )
}
