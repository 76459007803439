import React from "react"

export default function MoreInfo() {
  return (
    <div>
      <h1>About the Pollen Tube Growth Model</h1>
      <p className="text-sm leading-5 text-left text-gray-500">
        The pollen tube growth model (PTGM) was developed at Virginia Tech. It
        is based on apple pollen tubes growth rates that were empirically
        derived under controlled temperature conditions. Model validation has
        been conducted in Washington, Virginia, and New York orchards.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        The PTGM begins when the desired number of king bloom flowers are in
        full bloom (that is, when the petals no longer cover the reproductive
        organs thus allowing for cross-pollination). The desired number of open
        king bloom flowers is equal to the desired crop load and is determined
        by counting the number of open king bloom flowers per tree or by visual
        assessment of full bloom density in the orchard. Average style length is
        measured at this time and is used as a variable in the model.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Hourly temperatures recorded in or near the orchard are used with the
        pollen tube growth rate equations to calculate cumulative pollen tube
        growth. Chemical bloom thinning applications are made when the pollen
        tube lengths are equivalent to average style length. The supposition is
        that fertilization has occurred at this point.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Assuming that pollen tubes must grow the entire average style length on
        flowers that reached full bloom after an application of a bloom thinner,
        the model is reset after the bloom thinning application is made.
        Additional bloom thinning applications occur before pollen tubes grow to
        the end of the style to prevent additional fertilization. Applications
        cease at the end of bloom. Typically, two chemical thinning applications
        are necessary each year. Occasionally, a third application is necessary.
      </p>
      <p className="text-sm leading-5 text-left text-gray-500">
        Additional information about the Pollen Tube Growth Model and bloom
        thinning in the Eastern U.S. can be found in this{" "}
        <a
          href="http://nyshs.org/wp-content/uploads/2019/05/Peck-Pages-from-NYFQ-BOOK-WInter-2018.1-27-19-4.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Fruit Quarterly article
        </a>{" "}
        and this{" "}
        <a
          href="https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=2&ved=2ahUKEwjBipSg34niAhXBSt8KHeJTAnAQFjABegQIBBAC&url=https%3A%2F%2Fextension.psu.edu%2Fdownloadable%2Fdownload%2Fsample%2Fsample_id%2F3246%2F&usg=AOvVaw2ugQkcl6-xKESpELye0tgd"
          target="_blank"
          rel="noopener noreferrer"
        >
          Penn State Extension publication
        </a>
      </p>
    </div>
  )
}
