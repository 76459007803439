import React from "react"

export default function Acknowledgements() {
  return (
    <>
      <p className="text-sm leading-5 text-left text-gray-500">
        The pollen tube growth model (PTGM) was developed at Virginia Tech. It
        is based on apple pollen tubes growth rates that were empirically
        derived under controlled temperature conditions. Model validation has
        been conducted in Washington, Virginia, and New York orchards.
      </p>
    </>
  )
}
