import CustomInputHr from "./customInputHr"
import { getDayOfYear } from "date-fns"
import React from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import ReactTooltip from "react-tooltip"

export default function SprayDate({
  id,
  minDate = null,
  maxDate = null,
  datesOnlyInMs = [],
  spray,
  setUserSpray,
  tooltip,
  placeholderText = "",
  datesWithNoData = null,
  selectable,
}) {
  function handleChange(d) {
    if (d.getTime() < minDate.getTime()) {
      setUserSpray(minDate)
    } else {
      setUserSpray(d)
    }
  }

  function resetSprayDate() {
    setUserSpray(null)
  }

  const renderDayContents = (day, date) => {
    let title = ""
    if (datesWithNoData) {
      const dayOfYear = getDayOfYear(date)
      const isDayMissing = datesWithNoData.includes(dayOfYear)
      if (isDayMissing) title = "No Data Available"
    }
    return <span title={title}>{day}</span>
  }

  const filteredHourlyData = (selectedDate) => {
    if (selectedDate) {
      return datesOnlyInMs.includes(selectedDate.getTime())
    }
  }

  return (
    <div className="flex items-center justify-between">
      <DatePicker
        id={id}
        selected={spray}
        onChange={(d) => handleChange(d)}
        minDate={minDate}
        maxDate={maxDate}
        showTimeSelect
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:00 aa"
        timeIntervals={60}
        // popperPlacement="top-end"
        disabled={id === "startDate" ? false : !selectable}
        filterTime={filteredHourlyData}
        renderDayContents={renderDayContents}
        customInput={
          <CustomInputHr
            placeholderText={placeholderText}
            spray={selectable}
            id={id}
          />
        }
      />
      {spray && (
        <div className="">
          <button
            type="button"
            aria-label={`Clear ${spray}`}
            data-tip={tooltip}
            data-for="spray"
            onClick={resetSprayDate}
            className="flex items-center p-1.5 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
          >
            clear
          </button>
          <ReactTooltip
            id="spray"
            globalEventOff="click"
            // type="info"
            effect="solid"
            // aria-haspopup="true"
          />
        </div>
      )}
    </div>
  )
}
